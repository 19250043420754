import React from 'react'
import {FaPhoneAlt} from "react-icons/fa"

const MenuHeader = () => {
  return (
    <div className='menuHeader'>
        <FaPhoneAlt /><a href="tel:932007400"> 9302007400</a>
      </div>
  )
}

export default MenuHeader
