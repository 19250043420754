import React, { useState } from "react";

const Contact = (props) => {
  
  const [name, setName] = useState("");
  const [cityName, setCityName] = useState("");
  const [stateName, setStateName] = useState("Chhattisgarh");
  const [message, setMessage] = useState("");

  let finalStrToSend = "";

  const sendMessageToWhatsapp = () => {

    finalStrToSend = `Hey there,
from _*${name}*, *${cityName}, ${stateName}*_

Please get back on this asap :
_*${message}*_   
`;

    window.open(`https://wa.me/9302007100?text=${encodeURIComponent(finalStrToSend.trim())}`);
    return null;
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Make An Enquiry</h2>
                <p>
                  <i className="fa fa-map-marker"> Ghadi Chowk, Dhamtari</i>
                </p>
                <p>
                <i className="fa fa-phone"> <a href="tel:9302007100"> 9302007100</a>, <a href="tel:9302007200">9302007200 </a></i>

                
                </p>
                <p>
                  Please fill out the form below to send us your query on
                  whatsapp and we will get back to you asap.
                </p>
              </div>
              <form onSubmit={sendMessageToWhatsapp}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name or Enterprise Name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        required
                        onChange={(e) => setCityName(e.target.value)}
                      />
                    
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={stateName}
                        onChange={(e) => setStateName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Tell Us Your Message or Query"
                    required
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div id="success"></div>
                <p>{finalStrToSend}</p>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.007656261709!2d81.54661291504931!3d20.709913703962243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a2f28657f7389b5%3A0xacab248d4c545c1e!2sbharat%20supply%20agency!5e0!3m2!1sen!2sin!4v1660146152451!5m2!1sen!2sin"
                width="350"
                height="450"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="map"
              ></iframe>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.mail : "/"}>
                      <i className="fa fa-envelope"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.whatsapp : "/"}>
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Developed by {` `}
            <a href="mailto:teamhighhash@gmail.com" rel="nofollow">
              High Hash
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
